<template>
  <div>
    <b-row>
      <b-col v-show="!hideDate">
        <b-input v-model="dateValue" type="date" :id="id" :name="name + '-date'">
        </b-input>
      </b-col>
      <b-col>
        <b-input v-model="timeValue" type="time" :id="id + '-time'" :name="name + '-time'"></b-input>
      </b-col>
    </b-row>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import moment from 'moment'

export default {
  name: 'DatetimeInput',
  props: {
    id: String,
    value: String,
    hideDate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dateValue: '',
      timeValue: '',
    }
  },
  watch: {
    value: function() {
      this.init()
    },
    dateValue: function() {
      this.changed()
    },
    timeValue: function() {
      this.changed()
    },
  },
  computed: {
    name() {
      return this.id
    },
  },
  methods: {
    init() {
      if (this.value) {
        this.dateValue = moment(this.value).format('YYYY-MM-DD')
        this.timeValue = moment(this.value).format('HH:mm')
      } else {
        this.dateValue = ''
        this.timeValue = ''
      }
    },
    changed() {
      if (!this.dateValue && !this.timeValue) {
        this.$emit('input', null)
      } else {
        const value = this.dateValue + 'T' + this.timeValue
        if (moment(value).isValid()) {
          this.$emit('input', value)
        }
      }
    },
  },
  mounted() {
    this.init()
  },
}
</script>
<style scoped lang="less">
</style>
